import React, { useEffect, useState } from 'react'
import { CDropdown, CDropdownDivider, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import { CContainer, CHeader, CHeaderNav, CNavItem } from '@coreui/react'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { BsEnvelope } from 'react-icons/bs'
import PropTypes from 'prop-types'
import { AppHeaderDropdown } from './header/index'
import { pricingPlan, UserRoles } from 'data/Enums'
import { isAuthorized } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'
import catalogueServices from 'shared/services/catalogues.service'
import { useLocation } from 'react-router-dom'
import { FaBars, FaShoppingCart } from 'react-icons/fa'
import HomeIcon from '@mui/icons-material/Home'
import { BiSearch } from 'react-icons/bi'
import purchaseOrderService from 'shared/services/purchase-order-service'
import Notificationheader from 'components/notification/notification-header'
import { IoMdNotifications } from "react-icons/io"
import { useQuery } from 'react-query'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: 2,
    top: -12,
    border: `2px solid ${theme.palette.background.paper}`
  }
}))

const AppHeader = (props) => {
  const { handleToggle } = props
  const [messagesCount, setMessageCount] = useState(0)
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadChats, setUnreadChats] = useState()
  const contextObj = React.useContext(UserContext)
  const { showToast } = React.useContext(UserContext)
  const location = useLocation()
  const path = location.pathname
  const pathName = location.pathname.split("/")
  const { data: messages, refetch } = useQuery(['getNotifications'],
    async () => {
      const response = await purchaseOrderService.getNotificationList()
      return response?.data
    },
    {
      onSuccess: () => { },
      onError: (error) => {
        console.log("Fetching notifications :Error: " + error)
      },
    }
  )
  const markClearNotification = async (companyId) => {
    try {
      const apiRes = await purchaseOrderService.markAllAsRead(companyId)
      showToast(apiRes?.data?.message, true)
      await refetch()
    } catch (error) {
      showToast(error)
    } finally {
    }
  }
  const getUserQueries = () => {
    catalogueServices.getUnreadUserQueresCount(contextObj?.userData?.user?.id).then(
      (response) => {
        setMessageCount(response.data.messages)
      },
      () => { }
    )
  }

  const getAllUnreadChats = () => {
    purchaseOrderService.getAllUnreadChats(contextObj.userData.user.id).then(
      (response) => {
        setUnreadChats(response.data)
        setUnreadCount(response.data.overallTotalUnreadCount)
      }
    )
  }

  useEffect(() => {
    if (isAuthorized(contextObj?.userData?.user?.pricingPlan, pricingPlan?.Standard)) {
      if (UserRoles.SuperAdmin === contextObj?.userData?.user?.role) {
        // getCompanyQueries()
      } else {
        getUserQueries()
      }
    }
  }, [contextObj.userData])

  useEffect(() => {
    getAllUnreadChats()
  }, [contextObj.userData, location.pathname])

  return (
    <>
      <CHeader position='sticky' className={`${path == '/dashboard' ? 'dashboard-page' : path == '/' ? "dashboard-page" : path == '/products' ? "border-none" : pathName[3] == 'product-view' ? "border-none view-product-padding" : ""}`}>
        <CContainer fluid className={`${path == '/dashboard' ? 'justify-content-between' : path == '/' ? "justify-content-between" : path == '/products' ? "justify-content-between" : pathName[3] == 'product-view' ? "justify-content-between" : "justify-content-end"}`} >
          <div className='d-flex justify-content-center align-items-center'>
            <button className="btn-theme shadow-none rounded-1 me-3" onClick={() => handleToggle()}>{<FaBars />}</button>
            {path === '/dashboard' || path === '/' ? (
              <>
                <div className='d-flex align-items-center'>
                  <span className='d-flex align-items-center'>
                    <HomeIcon id="dash-icon" />
                    <span id="dashboard-text" className='ms-2'>Dashboard</span>
                  </span>
                </div>
              </>
            ) : ''}
            {path === '/products' ? (
              <>
                <div className='d-flex align-items-center'>
                  <FaShoppingCart id='pro-icon' size={25} />
                  <span id="product-text" className='ms-2'>Products</span>
                </div>
              </>
            ) : ''}
          </div>
          <div className='d-flex align-items-center justify-content-center flex-grow-1'>
            {path === '/dashboard' || path === '/' ? (
              <div className='d-flex align-items-center'>
                <BiSearch />
                <input type='text' style={{ width: 500 }} placeholder='Search' className='form-control border-0 ms-2' />
              </div>
            ) : ''}
          </div>
          <div className='d-flex align-items-center justify-content-end'>
            <CHeaderNav className='d-none d-md-flex'>
              <div className='d-flex align-items-center'>
                <CHeaderNav className='d-none d-md-flex'>
                  {isAuthorized(contextObj?.userData?.user?.pricingPlan, pricingPlan?.Standard) && (
                    contextObj?.userData?.user?.pomUserType === 3 || location?.pathname === "/orders" ? ('') : (
                      <>
                        <CDropdown variant='nav-item' className='me-3'>
                          <CDropdownToggle placement='bottom-end' className='py-0' caret={false}>
                            <Badge badgeContent={messages?.unread_count} color="success">
                              <IoMdNotifications fontSize={'1.3rem'} color="action" />
                            </Badge>
                          </CDropdownToggle>
                          <CDropdownMenu className='pt-0 mt-2' placement='bottom-end' >
                            <CDropdownHeader className='fw-semibold py-2 sticky-header'>
                              <li className='py-2 d-flex gap-2 justify-content-between'>
                                <span style={{ fontSize: 20 }} className='btn fw-bold'>Notification</span>
                                <button className='btn p-0 text-success fw-semibold' onClick={() => { markClearNotification(contextObj?.userData?.user?.companyId) }}>Mark all as read</button>
                              </li>
                            </CDropdownHeader>
                            <div className='notification-dropdown'>
                              <CDropdownItem >
                                <Notificationheader newNotification={true} refetch={refetch} NotificationHeading="Feed for you" checkLength={messages?.unread_notifications?.length} Array={messages?.unread_notifications} Notifications="Nothing new for you." />
                              </CDropdownItem>
                              <CDropdownDivider />
                              <CDropdownItem>
                                <Notificationheader NotificationHeading="Previous notifications" checkLength={messages?.read_notifications?.length} Array={messages?.read_notifications?.slice(0, 10)} Notifications="" readNotification={true} viewAll={messages?.read_notifications?.length >= 10} />
                              </CDropdownItem>
                            </div>
                          </CDropdownMenu>
                        </CDropdown>
                        <CNavItem>
                          <StyledBadge
                            // badgeContent={messagesCount}
                            badgeContent={unreadCount}
                            color='success'
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            invisible={unreadChats && unreadChats.chats.some(chat => chat.lastMessage.userId === contextObj.userData?.user?.id)}
                          >
                            <Link to='/messenger'>
                              <Badge badgeContent={messagesCount} color="primary">
                                <BsEnvelope className='notifications-icon' />
                              </Badge>
                            </Link>
                          </StyledBadge>
                        </CNavItem>
                      </>
                    )
                  )}
                </CHeaderNav>
                <CHeaderNav className='ms-3'>
                  <AppHeaderDropdown signOut={props.logOut} />
                </CHeaderNav>
              </div>
            </CHeaderNav>
          </div>

        </CContainer>
      </CHeader>
    </>
  )
}

AppHeader.propTypes = {
  logOut: PropTypes.func,
  currentUser: PropTypes.object,
  show: PropTypes.bool,
  handleToggle: PropTypes.func
}

export default AppHeader
