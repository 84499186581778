import React, { useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Checkbox,
  Fab,
  Skeleton
} from '@mui/material'
import { GrAdd } from 'react-icons/gr'
import PropTypes from 'prop-types'
import HorizontalStatus from 'components/common/status/HorizontalStatus'
import CatalogueList from './Toolbars/CatalogueList'
import EnhancedTableHead from './TableHeader'
import CircularProgress from '@mui/material/CircularProgress'

import './DataTable.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useLocation } from 'react-router-dom'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const DataTable = (props) => {
  const { poIsLoading, productsOnIdsLoading } = props
  const location = useLocation()
  const [clickedRows, setClickedRows] = useState({})
  const {
    searchKey,
    bookSearchKey,
    containerSearchKey,
    freightSearchKey,
    setBookSearchKey,
    setContainerSearchKey,
    setFreightSearchKey,
    setSearchKey
  } = props
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('createdOn')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleRequestSort = async (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    await props.setSortOn(property)
    await props.setSortOrder(isAsc ? -1 : 1)
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    handleChangePage('', 0)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  useEffect(() => {
    setPage(0)
    setRowsPerPage(10)
  }, [searchKey])

  const handleClick = (event, code) => {
    event.preventDefault()
    const selectedIndex = selected.indexOf(code)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (code) => selected.indexOf(code) !== -1
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0

  const fabStyle = {
    position: 'absolute',
    bottom: 60,
    right: 16
  }

  const addButtonClick = (e) => {
    e.preventDefault()
    props.add()
  }

  return (
    <Box sx={{ width: '100%' }}>
      {
        <CatalogueList
          numSelected={selected.length}
          searchKey={searchKey}
          bookSearchKey={bookSearchKey}
          containerSearchKey={containerSearchKey}
          freightSearchKey={freightSearchKey}
          setBookSearchKey={setBookSearchKey}
          setContainerSearchKey={setContainerSearchKey}
          setFreightSearchKey={setFreightSearchKey}
          setSearchKey={setSearchKey}
          sortOn={props.sortOn}
          setSortOn={props.setSortOn}
          sortOrder={props.sortOrder}
          setSortOrder={props.setSortOrder}
          items={props.items}
          showReviewList={props.showReviewList}
          setDraftBtn={props.setDraftBtn}
          draftBtn={props.draftBtn}
          setPo={props.setPo}
          setSupplier={props.setSupplier}
          setWarehouse={props.setWarehouse}
          setStart={props.setStart}
          setEnd={props.setEnd}
          setShipVia={props.setShipVia}
          fetchPurchaseOrders={props.fetchPurchaseOrders}
          fetchFilterData={props.fetchFilterData}
          handleFilterChange={props.handleFilterChange}
          supplier={props.supplier}
          po={props.po}
          warehouse={props.warehouse}
          arrivalWarehouse={props.arrivalWarehouse}
          shipVia={props.shipVia}
          start={props.start}
          end={props.end}
          vendors={props?.vendors}
          stage={props.stage}
          loadDate={props.loadDate}
          arrivalDates={props.arrivalDates}
          setArrivalDate={props.setArrivalDate}
          deliveryDate={props.deliveryDate}
          setDeliveryDate={props.setDeliveryDate}
          deliveryDateTo={props.deliveryDateTo}
          setDeliveryDateTo={props.setDeliveryDateTo}
          selectMonth={props.selectMonth}
          setSelectMonth={props.setSelectMonth}
          setLoadDate={props.setLoadDate}
          setStage={props.setStage}
          fetchProject={props.fetchProject}
          isSearch={props.isSearch}
          setSearch={props.setSearch}
          searcProducthData={props.searcProducthData}
          setSearchProductLoading={props.setSearchProductLoading}
          getAllPurchaseRefetch={props.getAllPurchaseRefetch}
          productDetails={props?.productDetails}
          setProductDetails={props?.setProductDetails}
          setFilterProductId={props.setFilterProductId}
        />
      }

      <>

        {props.rows.length > 0 && !props.isEmpty ? (
          <>
            <TableContainer
              className='catalogue-table-container table-width'
              sx={{
                '&::-webkit-scrollbar': {
                  width: 5,
                  height: 5
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'lightgrey'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#3c4b65',
                  borderRadius: 2
                }
              }}
            >
              <Table aria-labelledby='tableTitle' stickyHeader={!!props.stickyHeader} sx={{ minWidth: '90%' }}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={props.rows.length}
                  {...props}
                />

                {poIsLoading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={props.headCells.length}>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <div key={index} style={{ marginBottom: '0' }}>
                            <Skeleton key={index} animation='wave' height={100} />
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(props.rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, rowIndex) => {
                        const isItemSelected = isSelected(row.code)
                        const labelId = `enhanced-table-checkbox-${rowIndex}`
                        const isRowClicked = clickedRows === rowIndex

                        return (
                          <React.Fragment key={rowIndex}>
                            <TableRow
                              onClick={() => {
                                if (props.title === 'Purchase Order') {
                                  setClickedRows(!rowIndex)
                                }
                              }}
                              hover
                              role='checkbox'
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={rowIndex}
                              selected={isItemSelected}
                            >
                              {props.enableSelection && (
                                <TableCell padding='checkbox'>
                                  <Checkbox
                                    color='primary'
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId
                                    }}
                                    onClick={(event) => handleClick(event, row.code)}
                                  />
                                </TableCell>
                              )}

                              {props?.headCells.map((headCell, index) => {
                                return (
                                  <TableCell
                                    className={`${headCell.isSticky ? 'sticky-column hover-pointer' : 'hover-pointer'}`}
                                    component={index === 0 ? 'th' : ''}
                                    id={index === 0 ? labelId : ''}
                                    scope={index === 0 ? 'row' : ''}
                                    align={
                                      headCell.label !== 'Action' ? (headCell.numeric ? 'right' : 'left') : 'center'
                                    }
                                    key={index}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                  >
                                    {row[headCell.id]}
                                  </TableCell>
                                )
                              })}
                            </TableRow>

                            {productsOnIdsLoading && clickedRows === rowIndex && (
                              <TableRow>
                                <TableCell colSpan={props.headCells.length}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '100px'
                                    }}
                                  >
                                    <CircularProgress />
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                            {isRowClicked &&
                              props.title === 'Purchase Order' &&
                              props.productDetails &&
                              !productsOnIdsLoading && (
                                <TableRow>
                                  <TableCell colSpan={props.headCells.length}>
                                    <div style={{ overflowX: 'auto' }}>
                                      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <thead>
                                          <tr style={{ borderBottom: '1px solid #ccc' }}>
                                            <th style={{ padding: '8px', textAlign: 'left' }}>Image</th>
                                            <th style={{ padding: '8px', textAlign: 'left' }}>Sku</th>
                                            <th style={{ padding: '8px', textAlign: 'left' }}>Validated Qty</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {props?.productDetails?.length ? (
                                            props?.productDetails?.map((product) => {
                                              return (
                                                <tr key={product?.id} style={{ borderBottom: '1px solid #ccc' }}>
                                                  <td style={{ padding: '8px', textAlign: 'left' }}>
                                                    <img
                                                      src={
                                                        Array.isArray(product?.images) && product?.images.length
                                                          ? product?.images[0]
                                                          : ''
                                                      }
                                                      alt='not found'
                                                      width='60px'
                                                      height='60px'
                                                    />
                                                  </td>
                                                  <td style={{ padding: '8px', textAlign: 'left' }}>{product?.sku}</td>
                                                  <td style={{ padding: '8px', textAlign: 'left' }}>
                                                    {product?.validateQty}
                                                  </td>
                                                </tr>
                                              )
                                            })
                                          ) : (
                                            <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                              <td
                                                colSpan='7'
                                                style={{
                                                  fontSize: 20,
                                                  padding: '50px',
                                                  marginTop: '50px',
                                                  textAlign: 'center'
                                                }}
                                              >
                                                No Products Found
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )}
                          </React.Fragment>
                        )
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 55 * emptyRows
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {props?.rows?.length > 5 && (
              <TablePagination
                className='mt-5'
                rowsPerPageOptions={[10, 25, 50]}
                component='div'
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data-testid='table-pagination'
              />
            )}
          </>
        ) : (
          <HorizontalStatus
            imagesrc={require('assets/svgs/shared/empty.svg').default}
            mainmsg={props.defaultMessage}
            smallmsg={props.defaulSubMessage}
            isAbsolute={
              location?.pathname == '/projects/list' || location?.pathname.includes('/project/edit' || '/projects/list') ? false : true
            }
          />
        )}
        {!!props.add && (
          <Fab color='primary' aria-label='add' sx={fabStyle} onClick={(e) => addButtonClick(e)}>
            <GrAdd size='2.5em' />
          </Fab>
        )}
      </>
    </Box>
  )
}

DataTable.propTypes = {
  searchKey: PropTypes.string,
  bookSearchKey: PropTypes.string,
  containerSearchKey: PropTypes.string,
  freightSearchKey: PropTypes.string,
  rows: PropTypes.array,
  setSearchKey: PropTypes.func,
  setBookSearchKey: PropTypes.func,
  setContainerSearchKey: PropTypes.func,
  setFreightSearchKey: PropTypes.func,
  setSortOn: PropTypes.func,
  add: PropTypes.func,
  handleChange: PropTypes.func,
  setDraftBtn: PropTypes.func,
  setSortOrder: PropTypes.func,
  headCells: PropTypes.array,
  enableSelection: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  show: PropTypes.bool,
  title: PropTypes.string,
  sortOrder: PropTypes.number,
  sortOn: PropTypes.string,
  items: PropTypes.array,
  isEmpty: PropTypes.bool,
  defaultMessage: PropTypes.string,
  defaulSubMessage: PropTypes.string,
  showReviewList: PropTypes.bool,
  draftBtn: PropTypes.bool,
  cataloguesCount: PropTypes.number,
  setPo: PropTypes.func,
  setSupplier: PropTypes.func,
  setWarehouse: PropTypes.func,
  setStart: PropTypes.func,
  searcProducthData: PropTypes.func,
  isSearch: PropTypes.bool,
  setSearch: PropTypes.func,
  setEnd: PropTypes.func,
  setShipVia: PropTypes.func,
  setWarehouse: PropTypes.func,
  setStart: PropTypes.func,
  setEnd: PropTypes.func,
  handleFilterChange: PropTypes.func,
  fetchFilterData: PropTypes.func,
  fetchPurchaseOrders: PropTypes.func,
  supplier: PropTypes.string,
  po: PropTypes.string,
  warehouse: PropTypes.string,
  arrivalWarehouse: PropTypes.arrayOf().isRequired,
  shipVia: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  vendors: PropTypes.array,
  stage: PropTypes.string,
  loadDate: PropTypes.string,
  arrivalDates: PropTypes.string,
  setArrivalDate: PropTypes.func,
  deliveryDate: PropTypes.string,
  setDeliveryDate: PropTypes.func,
  deliveryDateTo: PropTypes.string,
  setDeliveryDateTo: PropTypes.func,
  selectMonth: PropTypes.string,
  setSelectMonth: PropTypes.func,
  setLoadDate: PropTypes.func,
  setStage: PropTypes.func,
  fetchProject: PropTypes.func,
  setHoverPoNumber: PropTypes.func,
  fetchPurchaseOrder: PropTypes.func,
  productDetails: PropTypes.array,
  setProductDetails: PropTypes.func,
  setFilterProductId: PropTypes.func,
  setSearchProductLoading: PropTypes.func,
  poIsLoading: PropTypes.bool,
  productsOnIdsLoading: PropTypes.bool,
  getAllPurchaseRefetch: PropTypes.func,
  catalogueHelper: PropTypes.object
}

export default DataTable
