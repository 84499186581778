import React, { useState } from 'react'
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
} from '@mui/material'
import PropTypes from 'prop-types'

const SalesReportTable = (props) => {
    const { salesReportData = {}, loading, isQuarterly = false } = props

    const [currentPage, setCurrentPage] = useState(0)
    const shopsPerPage = 10

    const marketplaces = Object.keys(salesReportData)
    if (loading) {
        return (
            <div className="d-flex mt-4 justify-content-center align-items-center">
                <CircularProgress />
            </div>
        )
    }

    if (!marketplaces.length) {
        return <div>No data available</div>
    }
    const months = salesReportData[marketplaces[0]].map((item) => item.month)
    const quarters = salesReportData[marketplaces[0]].map((item) => item.quarter)

    const periodLabels = isQuarterly ? quarters : months

    const periodTotals = periodLabels.map((label) => {
        let totalQty = 0
        let totalUntaxed = 0

        marketplaces.forEach((marketplace) => {
            const salesData = salesReportData[marketplace].find(
                (t) => t.month === label || t.quarter === label
            )
            if (salesData) {
                totalQty += salesData.qty || 0
                totalUntaxed += parseFloat(salesData.untaxed) || 0
            }
        })

        return { label, totalQty, totalUntaxed }
    })

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target
        if (scrollHeight - scrollTop <= clientHeight + 10) {
            setCurrentPage((prev) =>
                Math.min(prev + 1, Math.ceil(marketplaces.length / shopsPerPage) - 1)
            )
        }
    }

    return (
        <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
            <TableContainer
                className="scroll-box"
                onScroll={handleScroll}
                style={{ maxHeight: '100vh', overflowY: 'auto', width: '100%', position: 'relative' }}
            >
                <Table style={{ minWidth: '1200px', tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{
                                    padding: '16px 24px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    width: '200px',
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: '#fff',
                                    zIndex: 1,
                                }}
                                rowSpan={2}
                            >
                                Shops
                            </TableCell>
                            {periodLabels.map((label, index) => (
                                <TableCell
                                    key={index}
                                    align="center"
                                    style={{
                                        padding: '16px 24px',
                                        fontWeight: 'bold',
                                        width: '200px',
                                    }}
                                    colSpan={1}
                                >
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            {periodLabels.map((label, index) => (
                                <TableCell
                                    key={index}
                                    align="center"
                                    style={{
                                        padding: '8px 24px',
                                        width: '200px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '0 8px',
                                            backgroundColor: '#d9d9d9',
                                        }}
                                    >
                                        <span
                                            style={{
                                                padding: '2px 4px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            Qty
                                        </span>
                                        <span
                                            style={{
                                                padding: '2px 4px',
                                                borderRadius: '4px',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            Untaxed
                                        </span>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {marketplaces.slice(0, (currentPage + 1) * shopsPerPage).map((marketplace, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    style={{
                                        padding: '16px 24px',
                                        fontWeight: 'bold',
                                        width: '200px',
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: '#fff',
                                        zIndex: 1,
                                    }}
                                >
                                    {marketplace}
                                </TableCell>
                                {periodLabels.map((label) => {
                                    const salesData = salesReportData[marketplace].find(
                                        (t) => t.month === label || t.quarter === label
                                    )
                                    return (
                                        <TableCell
                                            key={label}
                                            style={{
                                                padding: '16px 24px',
                                                width: '200px',
                                                position: 'relative',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span>{salesData ? salesData.qty : 0}</span>
                                                <span>{salesData ? `€ ${Number(salesData.untaxed).toFixed(2)}` : '€ 0.00'}</span>
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: '50%',
                                                    height: '100%',
                                                    width: '1px',
                                                    backgroundColor: '#ccc',
                                                    transform: 'translateX(-50%)',
                                                }}
                                            />
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}

                        {/* Total Row */}
                        <TableRow
                            style={{
                                backgroundColor: '#f0f0f0',
                                fontWeight: 'bold',
                                position: 'sticky',
                                bottom: 0, // Stick to the bottom
                                zIndex: 2, // Ensure it stays above other content
                            }}
                        >
                            <TableCell
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: '#f0f0f0',
                                    zIndex: 3,
                                }}
                            >
                                Total
                            </TableCell>
                            {periodTotals.map((total, index) => (
                                <TableCell
                                    key={index}
                                    style={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        backgroundColor: '#f0f0f0',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '0 8px',
                                            width: '100%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span
                                            style={{
                                                padding: '2px 4px',
                                                borderRadius: '4px',
                                                backgroundColor: '#f0f0f0',
                                            }}
                                        >
                                            {total.totalQty}
                                        </span>
                                        <span
                                            style={{
                                                padding: '2px 4px',
                                                borderRadius: '4px',
                                                backgroundColor: '#f0f0f0',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            {`€ ${total.totalUntaxed.toFixed(2)}`}
                                        </span>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

SalesReportTable.propTypes = {
    salesReportData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    isQuarterly: PropTypes.bool,
}

export default SalesReportTable 
