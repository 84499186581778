import React, { useState } from 'react'
import { Grid, TableRow, TableCell, Table, TableHead, TableBody, TableContainer, CircularProgress, Collapse } from '@mui/material'
import PropTypes from 'prop-types'

const SalesReportShopTable = (props) => {
    const { shopOverallData = [], loading } = props
    const [expandedMonth, setExpandedMonth] = useState(null)

    const handleRowClick = (month) => {
        setExpandedMonth((prevMonth) => (prevMonth === month ? null : month))
    }

    const totalQty2024 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.qty_2024 || 0), 0)
    const totalUntaxed2024 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.untaxed_2024 || 0), 0)
    const totalQty2023 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.qty_2023 || 0), 0)
    const totalUntaxed2023 = shopOverallData?.monthlySales?.reduce((acc, curr) => acc + (curr?.untaxed_2023 || 0), 0)

    return (
        <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: 'center' }}><strong>Months</strong></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><strong>QTY Ordered 2024</strong></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><strong>Untaxed Total 2024</strong></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><strong>QTY Ordered 2023</strong></TableCell>
                            <TableCell sx={{ textAlign: 'center' }}><strong>Untaxed Total 2023</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(shopOverallData?.monthlySales) && shopOverallData?.monthlySales.length ? (
                            shopOverallData?.monthlySales.map((item, index) => (
                                <React.Fragment key={index}>
                                    {/* Main row */}
                                    <TableRow onClick={() => handleRowClick(item?.month)} style={{ cursor: 'pointer' }}>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.month || '--'}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.qty_2024 || 0}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.untaxed_2024 ? item.untaxed_2024.toFixed(2) : 0}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.qty_2023 || 0}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.untaxed_2023 ? item.untaxed_2023.toFixed(2) : 0}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={5} sx={{ padding: 0, border: 'none' }}>
                                            <Collapse in={expandedMonth === item?.month} timeout="auto" unmountOnExit>
                                                <div style={{ maxHeight: '450px', overflowY: 'auto', padding: '15px', paddingTop: '0px', backgroundColor: '#fafafa' }}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
                                                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '10px 5px' }}>Sr no.</TableCell>
                                                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '10px 5px' }}>SKU</TableCell>
                                                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', padding: '10px 5px' }}>Qty</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item?.sku_sales?.map((skuItem, skuIndex) => (
                                                                <TableRow key={skuIndex}>
                                                                    <TableCell sx={{ textAlign: 'center' }}>{skuIndex + 1}</TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>{skuItem?.sku || '--'}</TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>{skuItem?.qty || 0}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))
                        ) : !loading ? (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
                                        <p className='text-center'>No Records Found...</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : ""}

                        {shopOverallData?.monthlySales?.length > 0 && (
                            <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: '#1976d2' }}><strong>Total</strong></TableCell>
                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>{totalQty2024}</TableCell>
                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>{totalUntaxed2024.toFixed(2)}</TableCell>
                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>{totalQty2023}</TableCell>
                                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>{totalUntaxed2023.toFixed(2)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {loading && (
                <div className='d-flex mt-4 justify-content-center align-items-center'>
                    <CircularProgress />
                </div>
            )}
        </Grid>
    )
}

SalesReportShopTable.propTypes = {
    shopOverallData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default SalesReportShopTable
